import { useEffect, useState, useCallback, useRef } from 'react';
import './styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Plus } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { updateHeader } from '../../slices/header-slice';

import baseFilter from '../../assets/images/structures/base_filter.jpg';
import baseFilterDining from '../../assets/images/structures/base_filter_dining_table.png';
import baseFilterCoffee from '../../assets/images/structures/base_filter_coffee_table.png';
import baseFilterConsole from '../../assets/images/structures/base_filter_console_table.png';
import { LazyLoadedGroup } from '../../components/lazy-loaded-group';
import { groupArrayInChunks } from '../../utility/utils';
import PriceFilterSlider from '../../components/price-filter-slider';

const Bases = ({ type }) => {
    const navigate = useNavigate();
    const bases = useSelector((state) => state.app.bases);
    const dispatch = useDispatch();

    const [filters, setFilters] = useState([]);
    const [activeFilter, setActiveFilter] = useState(null);
    const [activeBaseFilter, setActiveBaseFilter] = useState(null);
    const [filterResults, setFilterResults] = useState([]);
    const [filterOpen, setFilterOpen] = useState(false);
    const [priceRange, setPriceRange] = useState([0, Infinity]);

    const sliderRef = useRef(null);

    const minPrice = Math.min(...bases.map(p => Math.floor(p.startingPrice)));
    const maxPrice = Math.max(...bases.map(p => p.startingPrice));

    // Set filters and initialize results
    useEffect(() => {
        dispatch(updateHeader('light'));

        if (bases) {
            const categories = bases.filter(b => b.material !== null).map(x => x.material);
            const unique = Array.from(new Set(categories));
            setFilters(unique);
            setFilterResults(groupArrayInChunks(bases, 8));
        }
    }, [dispatch, bases]);

    // Consolidated filtering logic
    const filterProducts = useCallback(() => {
        let result = bases;

        if (activeBaseFilter) {
            result = result.filter(x => x.type === activeBaseFilter);
        }

        if (activeFilter) {
            result = result.filter(x => x.material === activeFilter);
        }

        result = result.filter(x => x.startingPrice >= priceRange[0] && x.startingPrice <= priceRange[1]);

        setFilterResults(groupArrayInChunks(result, 8));
    }, [activeBaseFilter, activeFilter, priceRange, bases]);

    useEffect(() => {
        filterProducts();
    }, [filterProducts]);

    const clearFilter = useCallback(() => {
        setActiveFilter(null);
        setActiveBaseFilter(null);
        setPriceRange([minPrice, maxPrice]);
        setFilterResults(groupArrayInChunks(bases, 8));
        if (sliderRef.current) {
            sliderRef.current.resetSlider();
        }
    }, [bases, minPrice, maxPrice]);

    const onProductClick = (item) => {
        navigate({
            pathname: `/bases/${item.id}`,
            search: `?title=${item.title}`,
        });
    };

    const handlePriceChange = (range) => {
        setPriceRange(range);
    };

    return (
        <div className="bases-page">
            <div className="store-intro">
                <h1>Base Designs.</h1>
                <p>We provide a variety of pre-made Base Designs listed below...</p>
            </div>

            <div className="base-type-filter">
                <div className="base-filter info">
                    <img src={baseFilter} alt="base filter" />
                    <div>
                        <h2>Base Type</h2>
                        <p>Select to filter design choice.</p>
                    </div>
                </div>
                {['Dining Table', 'Coffee Table', 'Console Table'].map((type) => (
                    <div
                        key={type}
                        className={`base-filter ${activeBaseFilter === type ? "active" : ""}`}
                        onClick={() => setActiveBaseFilter(activeBaseFilter === type ? null : type)}
                    >
                        <img src={
                            type === 'Dining Table' ? baseFilterDining :
                            type === 'Coffee Table' ? baseFilterCoffee :
                            baseFilterConsole
                        } alt={`${type} Filter`} />
                        <div>
                            <p>{type}</p>
                        </div>
                    </div>
                ))}
            </div>

            <div className="tools-wrap">
                <div className="tools">
                    <div className="filter" onClick={() => setFilterOpen(!filterOpen)}>
                        {filterOpen ? <span>Close</span> : <span>Filters <Plus /></span>}
                    </div>
                </div>
                {filterOpen && (
                    <div className={`filter-content ${filterOpen ? "open" : ""}`}>
                        <div className="filter-options">
                            {filters.map((filter, i) => (
                                <div key={i}>
                                    <label className="custom-radio">
                                        <input
                                            type="radio"
                                            name="filter"
                                            checked={filter === activeFilter}
                                            onChange={() => setActiveFilter(filter === activeFilter ? null : filter)}
                                            value={filter}
                                        />
                                        <span className="checkmark"></span>
                                        {filter}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <PriceFilterSlider
                            ref={sliderRef}
                            minPrice={minPrice}
                            maxPrice={maxPrice}
                            onFilterChange={handlePriceChange}
                        />
                        <div className="filter-buttons">
                            <button className="cta dark" onClick={clearFilter}>Clear</button>
                        </div>
                    </div>
                )}
            </div>

            <div className="lazy-load">
                {filterResults.map((group, i) => (
                    <LazyLoadedGroup
                        key={i}
                        group={group}
                        index={i}
                        type={type}
                        onProductClick={onProductClick}
                    />
                ))}
            </div>
        </div>
    );
};

export default Bases;
