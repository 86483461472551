

import { Link } from 'react-router-dom';

import logo from '../../assets/images/footer/footer-logo.svg';
import instagram from '../../assets/images/footer/instagram.svg';
import facebook from '../../assets/images/footer/facebook.svg';
import youtube from '../../assets/images/footer/youtube.svg';
import pinterest from '../../assets/images/footer/pinterest.svg';
import tiktok from '../../assets/images/footer/tiktok.svg';

import './styles.scss';
import Subscribe from '../subscribe';

const Footer = () => {

    return (
        <div className='footer'>
            <div className='main'>
                <div className='content'>
                    <div className='col'>
                        <img className='logo' alt='' src={logo} />
                    </div>
                    <div className='col'>
                        <ul>
                            <li className='heading'>Shop</li>
                            <li><Link to="/table-builder">Table Builder</Link></li>
                            <li><Link to="/shop">Shop</Link></li>
                            <li><Link to="/custom-design">Custom Design</Link></li>
                        </ul>

                        <ul>
                            <li className='heading'>Studio</li>
                            <li><Link to="/portfolio">Portfolio</Link></li>
                            <li><Link to="/jeffrey-greene">Jeffrey Greene</Link></li>
                            <li><Link to="/our-story">Our Story</Link></li>
                            <li><Link to="/faqs">FAQs</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                            <li><Link to="/media">Media</Link></li>
                        </ul>
                    </div>
                    <div className='col'>
                        <ul>
                            <li className='heading'>Studio Address</li>
                            <li>
                                <a href="https://www.google.com/maps?q=6162+Lower+York+Road,+New+Hope,+PA+18938" target="_blank" rel="noopener noreferrer">
                                    6162 Lower York Road, New Hope, PA 18938
                                </a>
                            </li>
                        </ul>

                        <ul>
                            <li className='heading'>Contact</li>
                            <li className='clickable'>E: <a href='mailto:Hello@paramountwoodco.com'>Hello@paramountwoodco.com</a></li>
                            <li className='clickable'>T: <a href='tel:(412) 587-4166'>(412) 587-4166</a></li>
                        </ul>

                        <ul>
                            <li className='heading'>Studio Hours</li>
                            <li>Mon: 10 am to 6 pm</li>
                            <li>Tue & Wed: 10 am - 4 pm</li>
                            <li>Thu & Fri: 10 am - 6 pm</li>
                            <li>Sat: 10 am - 4 pm</li>
                            <li>Sun: Closed</li>
                        </ul>
                    </div>
                    <div className='col'>
                        <Subscribe />
                    </div>
                </div>
            </div>

            <div className='sub'>
                <div className='content'>
                    <div className='socials'>
                        <a href='https://www.instagram.com/paramountwoodco/' rel="noreferrer" target='_blank'><img src={instagram} alt='Paramount Wood Co. Instagram' /></a>
                        <a href='https://www.facebook.com/ParamountWoodCo/' rel="noreferrer" target='_blank'><img src={facebook} alt='Paramount Wood Co. Facebook' /></a>
                        <a href='https://www.youtube.com/@paramountwoodco7355/featured' rel="noreferrer" target='_blank'><img src={youtube} alt='Paramount Wood Co. Youtube' /></a>
                        <a href='https://pinterest.com/paramountwoodcoshop/' rel="noreferrer" target='_blank'><img src={pinterest} alt='Paramount Wood Co. Pinterest' /></a>
                        <a href='https://www.tiktok.com/@paramountwoodco' rel="noreferrer" target='_blank'><img src={tiktok} alt='Paramount Wood Co. TikTok' /></a>
                    </div>
                    <div className='copyright'>Copyright ©{new Date().getFullYear()}, Paramount Wood Co.</div>
                </div>
            </div>
        </div>
    )
}

export default Footer;