import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { updateHeader } from "../../slices/header-slice";
import { useLocation } from "react-router-dom";
import { contact } from "../../api";
import Animate from '../../components/animate';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import './styles.scss';
import FeedbackModal from "../../components/feedback-modal";
import { containsLiveEdge, getTableType } from "../../utility/utils";

const Contact = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const product = location?.state?.product;
    const finish = location?.state?.finish;
    const wishlist = location?.state?.wishlist;

    const getEnquiryType = () => {
        if (wishlist) {
            return "General Inquiry"
        } else if (product?.type) {
            return product.type
        } else if (product?.category && product?.category === "slabs") {
            return "Custom Item"
        } else if (product?.title) {
            return getTableType(product?.title)
        } else {
            return "General Inquiry"
        }
    }

    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [images, setImages] = useState([]);
    const [enquiryType, setEnquiryType] = useState(getEnquiryType());

    const [fileUploadText, setFileUploadText] = useState("Attach and upload an inspiration image");

    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        interest: product ? (product.title && product.inventoryId) ? product.inventoryId + ": " + product.title : product.title : "",
        details: wishlist ? wishlist.map((item) => (`${item.item.inventoryId ? item.item.inventoryId + " - " : ""}${item.item.title}`)).join('\n') : "",
        contactMethod: "Email",
        enquiryType: getEnquiryType(),
        color: finish ? finish : "",
        seats: "",
        budget: "",
        baseType: product && product.material && product.category && product.category === "bases" ? product.material === "Steel" ? "Metal base" : "Wood base" : "",
        edgeType: product?.title && containsLiveEdge(product.title) ? "Live edge" : "",
        honeyPot: ""
    });

    useEffect(() => {
        dispatch(updateHeader('dark'))
    }, [dispatch]);

    const submitContact = (e) => {
        e.preventDefault();
        setIsloading(true)

        const formData = new FormData();
        images.forEach((image, index) => {
            formData.append(`image${index + 1}`, image);
        });

        Object.keys(state).forEach(key => {
            formData.append(key, state[key]);
        });

        contact(formData).then((res) => {
            if (res.status === 200 || res.status === 204) {
                setIsSuccess(true);
            } else {
                setIsError(true);
            }

            setIsloading(false)
        }).catch((error) => {
            setIsError(true);
            setIsloading(false)
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((prev) => ({ ...prev, [name]: value }));
    };

    const onFeedbackClose = () => {
        setIsSuccess(false)
        setIsError(false)
    }

    const handleFileChange = (e) => {
        setImages([...e.target.files]);
        setFileUploadText(`${e.target.files.length} ${e.target.files.length > 1 ? "images" : "image"} selected. Thank you!`)
    };

    return (
        <>
            <div className="contact">

                <Animate>
                    <div className="text-section dark">
                        <h1>Contact us</h1>
                        <p>We hope you enjoyed viewing our work as much as we did making it. If you have a specific request or project idea, please don't hesitate to contact us directly through our contact form below.</p>
                    </div>
                </Animate>

                <Animate>
                    <div className="contact-form">
                        <form onSubmit={(e) => submitContact(e)}>
                            <div className="contact-inputs">
                                <div>
                                    <div className="form-input">
                                        <label>First name (Required)</label>
                                        <input type='text' name="firstName" defaultValue="" tabIndex="1" onChange={(e) => handleInputChange(e)} required />
                                    </div>
                                    <div className="form-input">
                                        <label>Email (Required)</label>
                                        <input type='email' name="email" defaultValue="" tabIndex="3" onChange={(e) => handleInputChange(e)} required />
                                    </div>

                                    <div className="form-input">
                                        <label>Preferred method of contact? (Optional)</label>
                                        <select
                                            defaultValue={state.contactMethod}
                                            onChange={(e) => handleInputChange(e)}
                                            name="contactMethod"
                                            tabIndex="5"
                                        >
                                            <option value="Email">Email</option>
                                            <option value="Phone">Phone</option>
                                        </select>
                                    </div>

                                </div>

                                <div>
                                    <div className="form-input">
                                        <label>Last name (Required)</label>
                                        <input type='text' name="lastName" defaultValue="" tabIndex="2" onChange={(e) => handleInputChange(e)} required />
                                    </div>

                                    <div className="form-input">
                                        <label>Phone number (Optional)</label>
                                        <input type='phone' name="phone" defaultValue="" tabIndex="4" onChange={(e) => handleInputChange(e)} />
                                    </div>

                                    <div className="form-input">
                                        <label>Inquiry type (Required)</label>
                                        <select
                                            defaultValue={state.enquiryType}
                                            onChange={(e) => { handleInputChange(e); setEnquiryType(e.target.value) }}
                                            required
                                            name="enquiryType"
                                            tabIndex="6"
                                        >
                                            <option value="General Inquiry">General inquiry</option>
                                            <option value="Dining Table">Dining table</option>
                                            <option value="Coffee Table">Coffee table</option>
                                            <option value="Custom Item">Custom item</option>
                                            <option value="Additional Services">Additional Services</option>
                                        </select>
                                    </div>

                                </div>
                            </div>

                            {(enquiryType === "Coffee Table" || enquiryType === "Dining Table") &&
                                <div className="contact-inputs">
                                    <div>
                                        <div className="form-input">
                                            <label>I'm interested in...</label>
                                            <input type='text' name="interest" defaultValue={state.interest} onChange={(e) => handleInputChange(e)} />
                                        </div>

                                        <div className="form-input">
                                            <label>Custom color?</label>
                                            <input type='text' name="color" defaultValue={state.color} onChange={(e) => handleInputChange(e)} />
                                        </div>

                                        <div className="form-input">
                                            <label>No. of seats?</label>
                                            <input type='text' name="seats" defaultValue={state.seats} onChange={(e) => handleInputChange(e)} />
                                        </div>

                                    </div>

                                    <div>
                                        <div className="form-input">
                                            <label>Wood or metal base?</label>
                                            <select
                                                defaultValue={state.baseType}
                                                onChange={(e) => handleInputChange(e)}
                                                required
                                                name="baseType"
                                            >
                                                <option value="">Select option...</option>
                                                <option value="Wood base">Wood base</option>
                                                <option value="Metal base">Metal base</option>
                                            </select>
                                        </div>
                                        <div className="form-input">
                                            <label>Live edge or finished edge?</label>
                                            <select
                                                defaultValue={state.edgeType}
                                                onChange={(e) => handleInputChange(e)}
                                                required
                                                name="edgeType"
                                            >
                                                <option value="">Select option...</option>
                                                <option value="Live edge">Live edge</option>
                                                <option value="Finished edge">Finished edge</option>
                                            </select>
                                        </div>
                                        <div>
                                            <p>(Optional, up to 3 total allowed, JPEG preferred)</p>
                                            <label className="custom-file-input" htmlFor="file-input">{fileUploadText}</label>
                                            <input id="file-input" name="file" type="file" max={3} min={1} multiple onChange={handleFileChange} />
                                        </div>
                                    </div>
                                </div>
                            }

                            {enquiryType === "Custom Item" &&
                                <div className="contact-inputs">
                                    <div>
                                        <div className="form-input">
                                            <label>I'm interested in...</label>
                                            <input type='text' name="interest" defaultValue={state.interest} onChange={(e) => handleInputChange(e)} />
                                        </div>

                                        <div className="form-input">
                                            <label>Budget?</label>
                                            <input type='text' name="budget" defaultValue="" onChange={(e) => handleInputChange(e)} />
                                        </div>
                                    </div>

                                    <div>
                                        <div className="form-input">
                                            <label>Live edge or finished edge?</label>
                                            <select
                                                defaultValue={state.edgeType}
                                                onChange={(e) => handleInputChange(e)}
                                                required
                                                name="edgeType"
                                            >
                                                <option value="">Select option...</option>
                                                <option value="Live edge">Live edge</option>
                                                <option value="Finished edge">Finished edge</option>
                                            </select>
                                        </div>

                                        <div>
                                            <p>(Optional, up to 3 total allowed, JPEG preferred)</p>
                                            <label className="custom-file-input" htmlFor="file-input">{fileUploadText}</label>
                                            <input id="file-input" name="file" type="file" max={3} min={1} multiple onChange={handleFileChange} />
                                        </div>
                                    </div>
                                </div>

                            }

                            <div className="contact-inputs full">
                                <div>
                                    <div className="form-input">
                                        <label>Inquiry details</label>
                                        <textarea defaultValue={state.details} rows={5} name="details" onChange={(e) => handleInputChange(e)} placeholder="Please provide any further details here..."></textarea>
                                    </div>
                                </div>
                            </div>

                            <input type="hidden" defaultValue="" name="honeyPot" />

                            <button type="submit" className={`cta gold-fill ${isLoading ? "loading" : ""}`}>
                                <div className="loader"></div>
                                <span>Submit</span>
                            </button>
                        </form>
                    </div>
                </Animate>

            </div>

            {isSuccess &&
                <FeedbackModal status={"success"} type={"contact"} title={"Email Sent"} message={"Thank you for your enquiry, you will be contacted soon by one of the Paramount Wood Co. team"} onClose={onFeedbackClose} label={"Close"} />
            }

            {isError &&
                <FeedbackModal status={"error"} type={"contact"} title={"Email failed to send"} message={"Sorry, something went wrong while trying to send your email. Please try again."} onClose={onFeedbackClose} label={"Close"} />
            }
        </>

    )
}

export default Contact