import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import ReactSlider from 'react-slider';
import './styles.scss'; // Create a CSS file for styling if needed

const PriceFilterSlider = forwardRef(({ minPrice, maxPrice, onFilterChange }, ref) => {
    const [value, setValue] = useState([minPrice, maxPrice]);
    const debounceTimeout = useRef(null);

    useImperativeHandle(ref, () => ({
        resetSlider: () => setValue([minPrice, maxPrice])
    }));

    useEffect(() => {
        setValue([minPrice, maxPrice]);
    }, [minPrice, maxPrice]);

    const handleSliderChange = (newValue) => {
        setValue(newValue);
    };

    const handleSliderAfterChange = (newValue) => {
        const correctedValue = [
            Math.max(newValue[0], minPrice),
            Math.min(newValue[1], maxPrice)
        ];
        setValue(correctedValue);
        debounceFilterChange(correctedValue);
    };

    const debounceFilterChange = (updatedValue) => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            onFilterChange(updatedValue);
        }, 500); // Adjust the delay (in milliseconds) as needed
    };

    const handleInputChange = (index, event) => {
        const inputValue = parseInt(event.target.value, 10);
        const updatedValue = [...value];
        updatedValue[index] = isNaN(inputValue) ? value[index] : inputValue;

        // Ensure the values remain in the correct range and order
        if (index === 0) {
            updatedValue[0] = Math.max(minPrice, Math.min(updatedValue[0], updatedValue[1]));
        } else {
            updatedValue[1] = Math.min(maxPrice, Math.max(updatedValue[1], updatedValue[0]));
        }

        setValue(updatedValue);
        debounceFilterChange(updatedValue);
    };

    return (
        <div className="price-filter-slider">
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="price-thumb"
                trackClassName="price-track"
                min={minPrice}
                max={maxPrice}
                value={value}
                onChange={handleSliderChange}
                onAfterChange={handleSliderAfterChange}
                ariaLabel={['Lower thumb', 'Upper thumb']}
                ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                pearling
                minDistance={1}
            />
            <div className="price-labels">
                <div className="price-input">
                    <input
                        type="number"
                        value={value[0]}
                        min={minPrice}
                        max={value[1]}
                        onChange={(event) => handleInputChange(0, event)}
                    />
                </div>
                <div className="price-input">
                    <input
                        type="number"
                        value={value[1]}
                        min={value[0]}
                        max={maxPrice}
                        onChange={(event) => handleInputChange(1, event)}
                    />
                </div>
            </div>
        </div>
    );
});

export default PriceFilterSlider;
