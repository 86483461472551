import { useCallback, useEffect, useState } from 'react';
import './styles.scss';
import { formatPrice } from '../../utility/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Heart } from 'react-feather';
import { setQuickAddItem, showPreview, updateCart, updateWishList } from '../../slices/app-slice';
import { motion } from "framer-motion";

const Item = ({ item, index, setItem, type = "standard" }) => {
    const [image, setImage] = useState({});
    const [secondImage, setSecondImage] = useState(null);
    const [isWishlistItem, setIsWishListItem] = useState(false);

    const wishlist = useSelector((state) => state.app.wishList)

    // const [showSecondImage, setShowSecondImage] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (item?.images?.length) {
            setImage(item.images[0]?.fileName)
            setSecondImage(null)

            if (item.images?.length > 1) {
                setSecondImage(item.images[1]?.fileName)
            }
        }

        const isOnWishlist = wishlist.filter(x => x.item.id === item.id && x.item.title === item.title).length > 0;

        setIsWishListItem(isOnWishlist)
    }, [item, wishlist])

    const quickView = (event) => {
        event.stopPropagation();

        dispatch(setQuickAddItem({
            item: item,
            type: "quickView"
        }))

        dispatch(showPreview(true))
    }

    const addToWishList = (event) => {
        event.stopPropagation();

        dispatch(updateWishList({
            item: item,
            type: item.category
        }))

        dispatch(setQuickAddItem({
            item: item
        }))

        dispatch(showPreview(true))
    }

    const quickAdd = (event, item) => {
        event.stopPropagation();

        dispatch(updateCart({
            product: item,
            quantity: 1,
            total: item.price
        }))

        dispatch(setQuickAddItem({
            product: item,
            quantity: 1,
            total: item.price
        }))

        dispatch(showPreview(true))
    }

    const imageHoverHandler = useCallback((image) => {
        if (image) {
            setImage(image)
        }
    }, [])

    // const imageLeaveHandler = useCallback(() => {
    //     if (image) {
    //         setImage(image)
    //     }
    // }, [image])

    return <>
        {type === "products" || type === "slabs" || type === "bases"
            ? <>
                {item && image &&
                    <div className='item-product-wrapper'>
                        {/* <div onMouseLeave={() => imageLeaveHandler()} onMouseEnter={() => imageHoverHandler()} onClick={() => { setItem(item) }} className='item-product'> */}
                        <div onMouseLeave={() => setImage(item.images[0]?.fileName)} onMouseEnter={() => imageHoverHandler(secondImage ? secondImage : image)} onClick={() => { setItem(item) }} className='item-product'>
                            <div className={`item-tag ${((item?.tag && item?.tag !== "None") || type === "slabs" || item.salePrice) ? "has-tag" : ""}`}>
                                {!item.salePrice && item?.tag && item?.tag !== "None" &&
                                    <div className={`pill ${item.tag.toLowerCase() === "best seller" ? "gold" : "bark"}`}>{item?.tag}</div>
                                }

                                {item.salePrice &&
                                    <div className={`pill coral`}>SALE</div>
                                }

                                {item.stock && item.stock > 0 ?
                                    <>
                                        {type === "products" && <div className="pill quick-add" onClick={(e) => quickAdd(e, item)}>+ Quick add</div>}
                                    </>

                                    : <div className="pill bark">Order this</div>
                                }

                                
                                {type === "slabs" && (item.startingPrice || item.seats || item.origin || item.grain) && <div className="pill quick-view" onClick={(e) => quickView(e)}>Quick View</div>}
                                {(type === "slabs" || type === "bases") && <div title="Wishlist this item" className="pill wishlist" onClick={(e) => quickView(e)}><Heart className={`wishlist-icon ${isWishlistItem ? "full" : ""}`} onClick={(e) => addToWishList(e)} /></div>}
                            </div>

                            {image &&

                                <motion.div
                                    key={image}
                                    variants={variants}
                                    animate={'show'}
                                    initial={'hide'}
                                    className='item-img-container'
                                >
                                    {/* <div className='item-img'>
                                        <img alt='' className={`first-image ${!showSecondImage ? "show" : "hide"}`} src={`${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/${type === "product" ? "products" : type}/${image}&width=600&height=800&quality=90&format=webp`} />
                                        <img alt='' className={`second-image ${showSecondImage ? "show" : "hide"}`} src={`${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/${type === "product" ? "products" : type}/${secondImage}&width=600&height=800&quality=90&format=webp`} />
                                    </div> */}

                                    <div className='item-img'>
                                        <div className='image-loader'>
                                            <div className="loader"></div>
                                        </div>
                                        <img alt='' src={`${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/${type}/${image}&width=600&height=800&quality=90&format=webp`} />
                                    </div>
                                </motion.div>

                            }
                            <div className={`item-details ${item.lightText ? "light" : ""}`}>
                                <p>{item?.title}</p>

                                <div className='price-wrapper'>
                                    {type !== "products" ? <></> : <p className={item.salePrice ? "strike-through" : ""}>{formatPrice(item?.price)}</p>}
                                    {item.salePrice && <p className='sale-price'>{formatPrice(item?.salePrice)}</p>}
                                </div>
                            </div>
                        </div>

                        <div className='variant-pickers'>
                            {Array.isArray(item?.variants) && item?.variants && item?.variants?.filter(x => x.type === "Material").map((variant, i) => {
                                return (
                                    <div key={i} onClick={() => { setItem(variant) }} title={variant.title} className='variant-picker'>{variant.thumbnail ? <img src={`${process.env.REACT_APP_WEBSITE_API}/images/thumbnails/${variant.thumbnail}`} alt='' /> : <></>}</div>
                                )
                            })}
                        </div>
                    </div>
                }
            </>
            : <div className='item hover-zoom' onClick={() => { setItem(item) }}>
                {image &&
                    <div className='item-bg' style={{ backgroundImage: `url("${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/projects/${image}&width=600&height=800&quality=90&format=webp")` }}> </div>
                }
                <div className='item-overlay'>
                    {item.title ? <p>{item.title}</p> : <></>}
                    {item.type ? <p>{item.type}</p> : <></>}
                </div>

            </div>
        }

    </>
}

export default Item;

export const variants = {
    show: {
        opacity: 1,
        y: 0,
        transition: {
            ease: 'easeIn',
            duration: 0.5
        }
    },
    hide: {
        y: 0,
        opacity: 0,
        transition: {
            ease: 'easeOut',
            duration: 0.5
        }
    }
};