
import './styles.scss';
import Subscribe from "../subscribe";

const SubscribeModal = ({ onClose }) => {
    return (
        <div className='subscribe-modal'>         
            <div className="subscribe-modal-content">
                <Subscribe onClose={onClose} />
            </div>
        </div>
    )
}

export default SubscribeModal;