import { useCallback, useEffect, useState } from 'react';
import './styles.scss';
import { formatPrice } from '../../utility/utils';
import { useDispatch } from 'react-redux';
import { Heart } from 'react-feather';
import { setQuickAddItem, showPreview, updateCart, updateWishList } from '../../slices/app-slice';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';

const DivergenceItem = ({ item, index, setItem, type = "standard" }) => {
    const [image, setImage] = useState({});
    const [secondImage, setSecondImage] = useState(null);
    const navigate = useNavigate();

    // const [showSecondImage, setShowSecondImage] = useState(false);
    const dispatch = useDispatch();

    const onProductClick = (item) => {
        //navigate(`/bases/${item.id}`);

        navigate({
            pathname: `/divergence/${item.id}`,
            search: `?title=${item.title}`,
        })
    };

    useEffect(() => {
        if (item) {
            const firstKey = Object.keys(item.variants)[0];
            const firstValue = item.variants[firstKey][0];

            console.log(firstValue)

            setImage(firstValue.images[0]?.fileName)
            setSecondImage(null)

            if (firstValue.images?.length > 1) {
                setSecondImage(firstValue.images[1]?.fileName)
            }
        }
    }, [item])

    const quickView = (event) => {
        event.stopPropagation();

        dispatch(setQuickAddItem({
            item: item,
            type: "quickView"
        }))

        dispatch(showPreview(true))
    }

    const addToWishList = (event) => {
        event.stopPropagation();

        dispatch(updateWishList({
            item: item,
            type: item.category
        }))

        dispatch(setQuickAddItem({
            item: item
        }))

        dispatch(showPreview(true))
    }

    const quickAdd = (event, item) => {
        event.stopPropagation();

        dispatch(updateCart({
            product: item,
            quantity: 1,
            total: item.price
        }))

        dispatch(setQuickAddItem({
            product: item,
            quantity: 1,
            total: item.price
        }))

        dispatch(showPreview(true))
    }

    const imageHoverHandler = useCallback((image) => {
        if (image) {
            setImage(image)
        }
    }, [])

    // const imageLeaveHandler = useCallback(() => {
    //     if (image) {
    //         setImage(image)
    //     }
    // }, [image])

    return <>
        {item && image &&
            <div className='item-divergence-wrapper'>
                {/* <div onMouseLeave={() => imageLeaveHandler()} onMouseEnter={() => imageHoverHandler()} onClick={() => { setItem(item) }} className='item-product'> */}
                <div onMouseLeave={() => setImage(image)} onMouseEnter={() => imageHoverHandler(secondImage ? secondImage : image)} onClick={() => { setItem(item) }} className='item-product'>
                    <div className={`item-tag ${((item?.tag && item?.tag !== "None") || type === "slabs") ? "has-tag" : ""}`}>
                        {item?.tag && item?.tag !== "None" &&
                            <div className={`pill ${item.tag.toLowerCase() === "best seller" ? "gold" : "bark"}`}>{item?.tag}</div>
                        }

                        {item.stock && item.stock > 0 ?
                            <>
                                {type === "products" && <div className="pill quick-add" onClick={(e) => quickAdd(e, item)}>+ Quick add</div>}
                            </>

                            : <div className="pill bark">Order this</div>
                        }

                        {type === "slabs" && <div title="Wishlist this item" className="pill wishlist" onClick={(e) => quickView(e)}><Heart className="wishlist-icon" onClick={(e) => addToWishList(e)} /></div>}
                        {type === "slabs" && (item.startingPrice || item.seats || item.origin || item.grain) && <div className="pill quick-view" onClick={(e) => quickView(e)}>Quick View</div>}
                    </div>

                    {image &&

                        <motion.div
                            key={image}
                            variants={variants}
                            animate={'show'}
                            initial={'hide'}
                            className='item-img-container'
                        >
                            {/* <div className='item-img'>
                                        <img alt='' className={`first-image ${!showSecondImage ? "show" : "hide"}`} src={`${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/${type === "product" ? "products" : type}/${image}&width=600&height=800&quality=90&format=webp`} />
                                        <img alt='' className={`second-image ${showSecondImage ? "show" : "hide"}`} src={`${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/${type === "product" ? "products" : type}/${secondImage}&width=600&height=800&quality=90&format=webp`} />
                                    </div> */}

                            <div className='item-img'>
                                <div className='image-loader'>
                                    <div className="loader"></div>
                                </div>
                                <img alt='' src={`${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/divergence/${image}&width=600&height=800&quality=90&format=webp`} />
                            </div>
                        </motion.div>

                    }
                    <div className={`item-details ${item.lightText ? "light" : ""}`}>
                        <p>{item?.title}</p>

                        {type !== "products" ? <></> : <p>{formatPrice(item?.price)}</p>}
                    </div>
                </div>            
            </div>
        }

    </>
}

export default DivergenceItem;

export const variants = {
    show: {
        opacity: 1,
        y: 0,
        transition: {
            ease: 'easeIn',
            duration: 0.5
        }
    },
    hide: {
        y: 0,
        opacity: 0,
        transition: {
            ease: 'easeOut',
            duration: 0.5
        }
    }
};